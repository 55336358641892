span.big_info {
    font-size: 2rem;
}

span.green {
    color: #4caf50;
}

span.red {
color: #aa2e25;
}